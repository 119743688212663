module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#f0e6f6',

  text: '#333333',

  primary: '#2aa553',
  primaryLight: '#28bd59',
  primaryDark: '#238c46',

  secondary: '#00a6c9',
  secondaryLight: '#02add1',
  secondaryDark: '#017891',
};
